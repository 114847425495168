<script setup lang="ts">
const emit = defineEmits<{
  (e: 'buttonClicked'): void
}>()

const props = defineProps<{
  icon: Function,
  color: 'black' | 'lime' | 'red'
}>()
</script>

<template lang="pug">
.product-item-button-container(class="group w-full h-full absolute inset-0")
  .product-item-button(
    :class="`color-${color}`"
    class="absolute flex shadow-lg text-white rounded-r w-36 h-12 bottom-8 -left-24 backdrop-blur-sm"
    class="transition-all duration-300 group-hover:left-0"
    class="[&.color-red]:bg-red-800/60 [&.color-lime]:bg-lime-600/60 [&.color-black]:bg-black/60"
    @click.prevent="emit('buttonClicked')"
  )
    .icon(class="flex items-center justify-center aspect-square")
      component(:is="icon" class="h-6")
    .text(class="flex items-center")
      slot
</template>
