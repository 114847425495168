<script setup lang="ts">
import { type IProductGetWithImages } from 'shared'
import { useShoppingCartStore } from '~/stores/shoppingCartStore'
import { PlusCircleIcon, HeartIcon, ShoppingBagIcon, NoSymbolIcon } from '@heroicons/vue/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/vue/24/solid'
import { useFavoriteStore } from '~/stores/favoriteStore'
import { useToastStore } from '../../../base-layer/store/toastStore'


const { product, showHoverAddToCart = true } = defineProps<{
  product: IProductGetWithImages,
  showHoverAddToCart?: boolean
}>()

const thumb = getItemThumb(product, 'sm', 'product')
const shoppingCartStore = useShoppingCartStore()

const authStore = useAuthStore()
const addToCart = () => {
  if (authStore.isLoggedIn == false) {
    navigateTo('/login')
    return
  }

  shoppingCartStore.add(product)
  useToastStore().toast({
    key: product._id,
    text: 'محصول مورد نظر به سبد خرید افزوده شد',
    title: 'سبد خرید',
    duration: 25000,
    type: 'success'
  })
}

const existsInCart = computed(() =>
  shoppingCartStore.checkProductExistInCart(product._id))

const favoriteStore = useFavoriteStore()
const isInFavoriteList = computed(() => favoriteStore.isFavorited(product._id))

const favoriteClicked = async () => {
  const item = favoriteStore.list.find(item => item.targetId == product._id)

  if (item)
    await favoriteStore.remove(item.targetId)
  else
    await favoriteStore.add(product._id)

  await favoriteStore.fetchList()
}

const { MessageModal, show: showAddToCartMessage } = useMessageModal({
  title: 'سبد خرید',
  message: 'محصول مورد نظر به سبد خرید افزوده شد',
  buttonText: 'متوجه شدم!'
})

const url = computed(() => `/product/${product._id}`)
</script>

<template lang="pug">
.product-item(class="group grid gap-4")
  NuxtLink.image(
    :to="url"
    class="overflow-hidden relative rounded-lg transition transition-shadow border border-transparent"
    class="hover:shadow-xl hover:border-gray-300 aspect-[3/2]"
  )
    figure(class="h-full")
      img(:src="thumb" :alt="product.name" class="size-full object-cover hover:scale-110 duration-300")

    template(v-if="showHoverAddToCart")
      template(v-if="product.inStock")
        template(v-if="existsInCart")
          ProductItemButton(:icon="ShoppingBagIcon" color="lime")
            span افزوده شده
        template(v-else)
          ProductItemButton(:icon="PlusCircleIcon" color="black" @buttonClicked="addToCart")
            span افزودن به سبد
      template(v-else)
        ProductItemButton(:icon="NoSymbolIcon" color="red")
          span ناموجود
    .like(
      @click.prevent="favoriteClicked"
      :class="{ liked: isInFavoriteList }"
      class="absolute top-3 right-3 p-2 hover:text-red-600 lg:hidden lg:group-hover:block"
    )
      template(v-if="isInFavoriteList")
        HeartIconSolid(class="h-8 text-red-500")
      template(v-else)
        HeartIcon(class="h-8")
  .details(class="grid gap-2 justify-items-center")
    NuxtLink.name(
      :to="url"
      class="text-xl font-semibold text-center hover:text-[--green-color-dark]"
      class="transition-border duration-200"
    ) {{ product.name }}
    template(v-if="product.inStock")
      div(class="flex items-center gap-4 font-semibold") 
        .discount {{ priceFormat(getProductDiscountedPrice(product) || 0) }} تومان
        template(v-if="product.discount && product.discount > 0")
          .price(class="line-through") {{ priceFormat(product.price || 0) }} تومان

MessageModal
</template>
