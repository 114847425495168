import { defineStore } from "pinia"
import type { IFavoriteGet, IID } from "shared"


export const useFavoriteStore = defineStore('favoriteStore', () => {
  const list = ref<IFavoriteGet[]>([])

  const isFavorited = (
    targetId: IID
  ) => {
    if (list.value == undefined)
      return false

    const item = list.value.find(item => item.targetId == targetId)
    return item != undefined
  }

  const fetchList = async () => {
    if (useAuthStore().isLoggedIn == false)
      return

    const { data, error } = await useApi<IFavoriteGet[]>('favorite')
    if (error.value == undefined)
      list.value = data.value
    else
      console.log(error.value)
  }
  fetchList()

  const add = async (
    productId: IID
  ) => {
    const { data, error } = await useApi('favorite', {
      method: 'post',
      body: {
        targetId: productId,
        targetType: 'product'
      }
    })

    if (error.value == undefined)
      return true
    else
      console.log(error.value)
  }

  const remove = async (
    targetId: IID
  ) => {
    const { data, error } = await useApi(`favorite/${targetId}`, { method: 'delete' })
    if (error.value == undefined)
      return data.value
    else
      console.log(error.value)
  }

  const clear = async () => {
    const { data, error } = await useApi('favorite/clear', { method: 'post' })

    if (error.value == undefined)
      console.log(data.value)
    else
      console.log(error.value)
  }

  return {
    list,
    isFavorited,
    fetchList,
    add,
    remove,
    clear,
  }
})
